class CustomPermission {

  constructor(object) {
    this.id = object.id;
    this.name = object.name;
    this.description = object.description;
    this.permission = object.permission;
  }

}

export default CustomPermission;
